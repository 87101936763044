import React from 'react';
import useCurrentUser from '../../hooks/use-current-user';
import { getHomeAppURL } from '../../utils/afp-config';
import FleetTechnicalSupportContact from '../fleet-technical-support/fleet-technical-support-contact';

const NotOpted = () => {
  const { isLoggedIn } = useCurrentUser();
  const homeLink = {
    href: `${getHomeAppURL()}${isLoggedIn ? '/home' : ''}`,
    text: 'Go back to homepage',
  };
  return (
    <div className="grid-row grid-gap margin-b-5">
      <div className="grid-col-12 tablet:grid-col-8">
        <h1 className="font-sans-2xl">Access denied</h1>
        <p className="font-body-lg">
          Your agency does not have access to this vehicle inventory management
          feature. To sign up for vehicle inventory management access, please
          contact your agency fleet manager.
        </p>
        <div className="margin-t-5">
          <ul className="usa-button-group">
            <li className="usa-button-group__item">
              <a href={homeLink.href} className="usa-button">
                {homeLink.text}
              </a>
            </li>
          </ul>
        </div>

        <p className="margin-bottom-4 margin-top-4">
          If you need assistance,
          <FleetTechnicalSupportContact />.
        </p>
      </div>
    </div>
  );
};

export default NotOpted;
