import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Typeahead } from '@gsa/afp-component-library';
import { PropTypes } from 'prop-types';
import { getVmsUrl } from '../../utils/afp-config';

export const GET_VEHICLES_BY_PARTIAL_TAG = gql`
   query GetVehiclesByPartialTagOrVin($tagNumber: String) {
    getVehiclesByPartialTagOrVin(tagNumber: $tagNumber) {
      tagNumber
      modelYear
      makeName
      id: vin
      makeColorName
      stateTagNumber
    }
  }
`;

export const renderSubComponent = (typeaheads, id, type) => {
  let itemIndex;
  if (type === 'vin') {
    itemIndex = typeaheads?.findIndex((taItem) => taItem.id === id);
  } else {
    itemIndex = typeaheads?.findIndex((taItem) => taItem.tagNumber === id);
  }
  const item = typeaheads[itemIndex];
  return (
    item && (
      <div className="display-flex flex-column">
        <span className="text-ink">
          <div>{item?.tagNumber}</div>
          <div>{item?.id ? item?.id : ''}</div>
        </span>
        <span className="text-base">
          {item?.modelYear} {item?.makeName}&nbsp;
          {item?.modelName}&nbsp;
          {item?.makeColorName && `(${item.makeColorName})`}
        </span>
      </div>
    )
  );
};

function TagLookup({ onSelect, disabled, handleError }) {
  const [getVehiclesByPartialTagOrVin, { data }] = useLazyQuery(
    GET_VEHICLES_BY_PARTIAL_TAG,
    {
      context: {
        clientName: 'vms',
      },
      fetchPolicy: 'no-cache',
      onError(err) {
        // eslint-disable-next-line no-console
        console.warn(err);
        handleError('Technical error occurred');
      },
    },
  );

  const [typeaheads, setTypeaheads] = useState([]);
  const [typeaheadValues, setTypeaheadValues] = useState([]);

  useEffect(() => {
    if (data) {
      setTypeaheads(data?.getVehiclesByPartialTagOrVin);
      handleError('');
    }
  }, [data]);

  useEffect(() => {
    const ids = [];
    if (typeaheads && typeaheads.length > 0) {
      typeaheads.forEach((item) => {
        ids.push(item.tagNumber);
      });
    }
    setTypeaheadValues(ids);
    // route to vehicle details if only one matching result
    if (typeaheads?.length === 1) {
      onSelect
        ? onSelect(typeaheads[0].id)
        : (window.location.href = `${getVmsUrl()}/vehicles/${encodeURIComponent(
            typeaheads[0].id,
          )}/overview`);
    }
  }, [typeaheads]);

  const fetchVehicles = (_, val) => {
    if (val) {
      getVehiclesByPartialTagOrVin({
        variables: { tagNumber: val },
      });
    }
  };

  const handleSelect = (tag) => {
    if (tag) {
      const typeaheadValue = typeaheads.find((item) => item.tagNumber === tag);
      onSelect
        ? onSelect(typeaheadValue.id)
        : (window.location.href = `${getVmsUrl()}/vehicles/${encodeURIComponent(
            typeaheadValue.id,
          )}/overview`);
    }
  };

  const handleClear = () => {
    onSelect && onSelect('');
  };

  return (
    <Typeahead
      id="tagNumber"
      filterValue=""
      fetchTypeaheadValues={fetchVehicles}
      typeaheadValues={typeaheadValues}
      placeholder="Enter federal license plate"
      inputCharNum={4}
      ariaLabel="Enter federal license plate"
      debounceDelay={500}
      promptText="Search requires 4 characters"
      noResultsText="No results found. The license plate does not exist or you do not have permission to access the attached vehicle."
      generateCustomOption={(tag) => renderSubComponent(typeaheads, tag, 'tag')}
      onOptionEnter={handleSelect}
      disabled={disabled}
      onClear={handleClear}
    />
  );
}

TagLookup.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  handleError: PropTypes.func,
};
TagLookup.defaultProps = {
  onSelect: undefined,
  disabled: false,
};

export default TagLookup;
